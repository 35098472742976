
import React from "react"
import Hotbar from "../elements/Hotbar"
import './rules.css'

export default function Rules() {
    return (
        <div className="rules">
            <div className="content-body">
                <div className="full-article">
                    <p>
                        Rules
                    </p>
                    <Hotbar />
                    <p>
                        The Mooks card game is coming soon!
                    </p>
                    <p>We have a reddit here <a href="https://www.reddit.com/r/mookstcg/" target="_blank">mookstcg</a></p>
                    <p>
                        Interested in playtesting? A discord? Just want to learn more? Email me at info[at symbol]mookstcg.com!
                    </p>

                    <h1 className="rules-title">Preparing for the game</h1>

                    <p>Each player needs a deck of 60 cards (called their arsenal).
                        Arsenals can only contain at most three of any cards with the same name.
                        If a card is Unique, an arsenal can only have one copy of that card.</p>

                    <p>Each player also needs a Faction card, which represents the faction for their arsenal.</p>

                    <p>That's it! The game is purposefully designed to not need anything but the cards you bring.</p>

                    <h1 className="rules-title">Starting the game</h1>

                    <p>Each player puts their faction card on the table, then shuffles their arsenal.</p>

                    <p>Designate an area on the table for your garbage pile, usually close to your arsenal.
                        This is where cards go when they're used up.</p>

                    <p>Decide who goes first. You can roll a die, flip a coin, rock-paper-scissors, whatever. Our team likes to choose who goes first by whoever most recently committed a crime.</p>

                    <p>Finally, each player draws six cards and play begins.</p>

                    <h1 className="rules-title">Turn Order</h1>

                    <p>Each player takes turns.</p>

                    <p><b>NOTE</b> you should remember your turn count for the first couple of turns, a card that cares about card count only cares about its controller's and it determines how many Mooks you can hire.</p>

                    <h2 className="rules-subtitle">What you can do during a turn</h2>

                    <p>At the start of your turn, you draw a card, and then bring home all of your mooks that are away. Then abilities that trigger "at the start of your turn" trigger.

                        Each turn, you can:

                        <ul>
                            <li>play 1 scheme</li>
                            <li>fabricate 1 machine that's been facedown since the start of the turn. You can also play any number of machines facedown</li>
                            <li>build any number of bases. If it's your only base, it becomes your active base automatically</li>
                            <li>hire a number of mooks up to your current turn count, or until you control the most mooks. If the count is 3 or higher, you can hire as many mooks as you want</li>
                            <li>play any number of blueprints from your hand</li>
                        </ul>
                    </p>

                    <h2>Operations</h2>

                    <p>
                        Some cards give your mooks abilities that let them start an <b>operation</b>. These abilities are formatted like "<b>Operation Example (10) - Do stuff.</b>"
                    </p>
                    <p>    
                        To perform an operation, declare your intent to use an operation ability you haven't used this turn as well as any of its targets. Some cards let players 
                        respond to this declaration, so let a weighty pause settle over the table. Maybe glare at the other players for a moment. 
                    </p>
                    <p>You then choose one of your mooks to be the "leader" of the operation, and additional mooks whose total threat level with the leader is higher than the number
                        in parentheses after the operation
                        name (this is the operation's threat level requirement). If you don't have enough threat level, the operation fails and you can't try again this turn. Otherwise 
                        those mooks "join" the operation and all of the mooks, including the leader, become operatives. You now perform the ability's effects. After the ability's
                        effects are resolved, surviving operatives are sent "away" (they're out in the field, traveling home. Move them a little to the side so you don't forget they're away.).
                    </p>
                    <p>
                        <b>Note: only mooks that are at home can start or join an operation.</b>
                    </p>

                    <h1 className="rules-title">Winning the game</h1>
                    <p>
                        Players lose if they go to draw a card and have none left in their arsenal. Otherwise you only lose if someone else wins.
                    </p>
                    <p>
                        A player wins when either a card effect says they win, or all of the other players have lost.
                    </p>
                    <h1 className="rules-title">General Card Rules</h1>

                    <h2>Requirements</h2>

                    <p>Cards of all types can have requirements written on them that define when you're able to use them. 
                        A machine may say "Requires 2 mooks" — that means you have to have 2 mooks out to fabricate the machine. 
                        The same goes with hiring mooks and playing bases and schemes. Once you play the card you no longer 
                        need to meet the requirements, so if you stop meeting the requirements the cards don't blow up.</p>

                    <h2>Faction-specific cards</h2>

                    <p>Some cards say "[Faction] Only". You can only put these cards in your arsenal if your Faction card is that 
                    faction. See the rules on Factions below for more information.</p>

                    <h2 className="rules-subtitle">Mooks</h2>

                    <p>Every mook has a threat level that represents interpol's opinion of their threat towards the current zeitgeist. Basically, a higher threat level is better. If a 
                        mook ever has a threat level of 0 they blow up (this is them retiring, turning in their badge, or buying the farm).</p>

                    <h2 className="rules-subtitle">Schemes</h2>

                    <p>Once during your turn, when nothing else is happening, you can play a scheme from your hand.</p>
                        
                    <p> You can also play a scheme any time an event occurs that meets the scheme's timing restrictions, even if it's not your turn. For example, if another player
                        hires a mook and you have a scheme that says "You can play this scheme when a mook is hired", you can play that 
                        scheme immediately. This doesn't get around the one scheme card per turn restriction, though.</p>

                    <h3>Playing Schemes</h3>

                    <p>To play a scheme, put the scheme card on the table and declare all of its targets. You can't play a scheme if you can't choose all of its targets. Next, if the card says "do X as a cost", you must do X. Then you do what the scheme says. After you're done, put the scheme card in your garbage pile.</p>
                    
                    <p><b>Note:</b> In Mooks, whenever you use a card or ability you always do as much as the card can do. So if you play a scheme and someone responds in a way that blows up one or more of the scheme's targets, don't throw the scheme away — just do as much as you can do.</p>

                    <h2 className="rules-subtitle">Bases</h2>

                    <p>If you have any base cards on the table, you have an active base. When you play your first base card, that immediately becomes your active base. Make sure you clearly denote which base is your active one, so no one gets confused if you have several bases. If your current active base is ever blown up, you immediately choose another of your bases to become your new active base.

                    You only get the effect of your active base, including only being able to use operations provided by your active base.</p>

                    <h3>Changing Active Base</h3>

                    <p>Once per turn you can change your current active base to another of your bases.</p>

                    <h2 className="rules-subtitle">Blueprints</h2>

                    <p>Blueprints are the ultimate expression of mad science, corporate might or government planning. Blueprints represent the Mad Scientist faction's win condition.

                    You can play any number of blueprints during your turn.</p>

                    <h2 className="rules-subtitle">Machines</h2>

                    <p>You can order any number of machine cards facedown during your turn. Facedown machines don't count as machines for the purposes of targeting, paying costs, or meeting requirements.</p>

                    <p>Once during your turn, when nothing else is happening, you can fabricate a machine you've controlled since the start of your turn. To fabricate it, turn it face up.</p>

                    <h2 className="rules-subtitle">Factions</h2>

                    <p>Faction cards define what your faction is and what your playstyle will be. There are four factions (so far): Mad Scientists, Mercs, Corporations, and Governments. These cards exist outside of your arsenal, and you start the game with them out. If using sleeves, it can be helpful to sleeve them with a different color so you don't shuffle them in!</p>
                        
                    <p>There are several cards that say "[Faction] Only", which means you can only have them in your arsenal if you match that faction.</p>

                    <h2 className="rules-subtitle">Tokens</h2>

                    <p>To hire a token, take the top card of your arsenal and hire it facedown as a mook with threat level 5. If a card can target facedown cards, it can target tokens. If a token is ever flipped faceup by a card ability, it's blown up if it's not a mook (if it <i>is</i> a mook, you get to keep it).</p>

                    <h2 className="rules-subtitle">Keyword Glossary</h2>

                    <ul>
                        <li><b>Invincible</b>: This Mook cannot be blown up (it can still be targeted by things that would blow it up, it just doesn't get blown up by them).</li>

                        <li><b>Unique</b>: If a card has Unique, an arsenal can only contain one copy of it.</li>
                    </ul>

                    <h2 className="rules-subtitle">Additional General Rules and FAQ</h2>

                    <ul>
                    <li>Whenever you search for a card with a stipulation (ie "a machine" or "a mook with threat level 4 or lower") you must reveal that card.</li>

                    <li>Whenever you search your arsenal, you must shuffle afterwards.</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}